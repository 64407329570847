/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS (and SCSS, if configured) file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

 @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&lang=en");

#login-page{
    margin: -2px 108px;
}

.dataTable th {
  background-color: #2fc0c5;
}

.commission_reports thead th {
  background-color: #2fc0c5;
}


.dt-scroll th,
.dt-scroll td {
  border: 1px solid rgb(236, 225, 225) !important;
  border-collapse: collapse;
}

#commission_report {
  margin: 10px;
}

.allow_filter {
  margin: 10px;
}

#commission_reports tbody {
  font-size: 12px;
}
